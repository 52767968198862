
a{
  color: currentColor;
  font-weight: 700;
  text-decoration: none;
  &:hover{
    color: currentColor;
    text-decoration: underline;
  }
}
p{
  margin: 0 0 $spacer-sm 0;
  font-size: min(max(15px, 4vw), 17px);
  font-weight: 300;
  line-height: 1.4;
}
h1 {
  font-size: min(max(48px, 4vw), 60px);
  font-weight: 300;
  letter-spacing: -.17rem;
  margin: 0 0 0 -4px;
  line-height: 1;
}
h2 {
  font-size: min(max(22px, 4vw), 28px);
  font-weight: 700;
  letter-spacing: -.04rem;
  line-height: 1.1;
  margin: 0 0 $spacer-sm 0;
}
h3 {
  font-size: min(max(18px, 4vw), 20px);
  font-weight: 700;
  letter-spacing: -.02rem;
  line-height: 1.2;
  margin: 0 0 $spacer-xs 0;
}
h4 {
  font-size: min(max(13px, 4vw), 15px);
  font-weight: 700;
  letter-spacing: -.02rem;
  line-height: 1.2;
  margin: 0 0 $spacer-xs 0;
}

blockquote{
  margin: $spacer-md 0;
  padding: 0;
  max-width: 70ch;
  opacity: .7;
  p{
    font-size: min(max(20px, 4vw), 24px);
    font-weight: 300;
    letter-spacing: -.02rem;
    line-height: 1.4;
    margin: 0 0 $spacer-sm 2px;
    padding: 0 0 0 $spacer-sm;
    border-left: 6px solid currentColor;
  }
}
