section.footer {
  padding: $spacer-xl $page-margin $spacer-xxl $page-margin;
  .container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr) );
  }
  .block-wrapper{
    p{
      margin-bottom: $spacer-md !important;
      font-size: min(max(13px, 4vw), 14px);
      line-height: 1.3;
      opacity: .7;
    }
  }
}


@media screen and (max-width: 768px) {
  section.footer {
    .container{
      grid-template-columns: repeat(auto-fill, minmax(90%, 1fr) );
    }
  }
}
