*, *::after, *::before {
  box-sizing: border-box;
}
// --------------------------------- DESKTOP ---------------------------------

// HAMBURGER
#nav-span-wrapper{
  display: none;
}
header{
  position: relative;
}
.nav-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
	width: $drawer-width;
	height: 100vh;
  z-index: 20;
  transition: transform $animation-duration $animation-timing;
}
.logo-mark{
  position: relative;
	padding: 3rem;
  z-index: 10;
  img {
    width: 90%;
    height: auto;
  }
}
.nav{
  position: relative;
  list-style: none;
  padding: 0 3rem;
  margin: 0;
  overflow: visible;
  z-index: 20;
  li{
    display: block;
    line-height: 1.9;
    margin: 0;
		padding: 0;
    a{
      font-size: min(max(16px, 4vw), 18px);
			font-weight: 400;
      letter-spacing: -.02rem;
			text-decoration: none;
      color: currentColor;
      padding: 0;
      margin: 0;
      transition: all .3s ease;
			&::after {
		    content: "\f054";
		    position: relative;
		    top: -1px;
		    margin-left: $spacer-xxs;
		    font-family: "Font Awesome 5 Pro";
		    font-size: min(max(8px, 4vw), 9px);
		    font-weight: 900;
		    color: currentColor;
		    opacity: 0;
		    transition: all 0.4s ease;
		  }
			i{
				font-size: min(max(12px, 4vw), 14px);
				width: 24px;
			}
		  &:hover {
		    color: currentColor;
		    &::after {
		      opacity: 1;
		    }
		  }
			&.current {
				font-weight: 700;
		    color: currentColor;
		    &::after {
		      opacity: 1;
		    }
		  }
    }
  }
}






// --------------------------------- MOBILE ---------------------------------
@media screen and (max-width: 1024px) {
  .nav-open main {
    transform: translateX(0%);
  }
  // HAMBURGER
  #nav-span-wrapper{
    position: absolute;
    top: 2.5rem;
    right: -36px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    z-index: 30;
    transition: all .3s ease;
  }
  a{
    &.nav-trigger {
      position: relative;
      display: flex;
			flex-direction: row;
			justify-content: center;
      align-items: center;
      height: 34px;
      width: 32px;
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
			background-color: $black;
			svg {
				&.close{
					display: none;
				}
				&.open{
					display: block;
					width: 60%;
					fill: $white;
				}
			}
    }
  }
  .nav-open{
    a{
      &.nav-trigger {
        svg {
          &.close{
            display: block;
            width: 55%;
            fill: $white;
          }
          &.open{
            display: none;
          }
        }
      }
    }
  }

  // OVERLAY
  .overlay {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: $black;
    transition: all .3s ease;
  }
  .nav-open .overlay {
    opacity: .7;
  }
  // DRAWER
	.nav-container {
		transform: translateX(-100%);
	  transition: transform $animation-duration $animation-timing;
	}
  .nav-open .nav-container {
    transform: translateX(0%);
  }
}


@media print {
  .nav-container {
    display: none;
  }
}
