.sr-only{
  @include sr-only;
}


// GRID SPAN
.span-all{
  grid-column: 1/-1;
}
.span-2{
  grid-column: auto / span 2;
}


// COLORS
.primary-dark{
  background-color: $primary-color-dark;
}
.primary{
  background-color: $primary-color;
}
.secondary{
  background-color: $secondary-color;
}
.tertiary{
  background-color: $tertiary-color;
}
.accent{
  background-color: $accent-color;
}
.neutral{
  background-color: $neutral-color;
}

.pd-t-lg{
  padding-top: $spacer-lg;
}
