@import "variables";
@import "mixins";
@import "navigation";
@import "footer";
@import "typography";
@import "helpers";


body {
  font-size: 16px;
  line-height: 1.3;
  font-family: $font-sans;
  font-weight: 300;
  color: $black;
  background-color: $white;
  margin: 0;
  padding: 0;
  main{
    padding-left: $drawer-width;
  }
}

@media screen and (max-width: 1024px) {
  body {
    main{
      padding-left: 0;
    }
  }
}
