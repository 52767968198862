ul{
  // BULLETS
  margin: 0 0 $spacer-sm 1.25rem;
  padding: 0;
  list-style: disc;
  li{
    font-size: min(max(14px, 4vw), 16px);
    line-height: 1.3;
    margin: 0 0 $spacer-xxs 0;
    p{
      margin: 0;
    }
	}
}

ol{
  // BULLETS
  margin: 0 0 $spacer-sm 1.25rem;
  padding: 0;
  li{
    font-size: min(max(14px, 4vw), 16px);
    line-height: 1.3;
    margin: 0 0 $spacer-xxs 0;
    p{
      margin: 0;
    }
	}
}
