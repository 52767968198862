section.intro {
  padding: 3.25rem $page-margin 0 $page-margin;
  .container{

  }
  .copy{

  }
  .site-info{
    font-size: min(max(12px, 4vw), 15px);
    font-weight: 300;
    letter-spacing: -.01rem;
    opacity: .4;
    margin-bottom: 4rem;
  }
  .icon{
    i{
      font-size: min(max(32px, 4vw), 42px);
      font-weight: 100;
      margin-bottom: $spacer-xxs;
    }
  }
}
