a.button {
  position: relative;
  display: inline-block;
  padding: $spacer-xs $spacer-sm;
  margin: 0;
  font-weight: 700;
  font-size: min(max(14px, 4vw), 16px);
  letter-spacing: -.01rem;
  text-decoration: none;
  position: relative;
  border-radius: 3px;
  border: 0;
  box-shadow: $shadow;
  transition: all 0.3s ease;
  z-index: 10;
  &::after {
    content: "\f054";
    position: relative;
    top: -1px;
    margin-left: $spacer-xxs;
    font-family: "Font Awesome 5 Pro";
    font-size: min(max(8px, 4vw), 9px);
    font-weight: 900;
    color: currentColor;
    opacity: 0;
    transition: all 0.4s ease;
  }
  &:hover {
    color: currentColor;
    &::after {
      opacity: 1;
    }
  }
}
