section.blocks {
  padding: $gutter $page-margin;
  .container{

  }
  .copy{
    padding: $spacer-md 0 0 0;
    p{
      max-width: 75ch;
    }
    .button{
      margin-bottom: $gutter;
    }
  }
  .images{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $gutter;
    padding: 0 0 $gutter 0;
  }
  .images-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $gutter;
    padding: 0 0 $gutter 0;
  }
  .violation-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $gutter;
    padding: 0 0 $gutter 0;
  }
  .colors-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $gutter;
    padding: 0 0 $gutter 0;
    h3{
      margin-bottom: -.45rem;
    }
  }
  .swatch{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 300px;
    border-radius: 3px;
    box-shadow: $shadow;
    .description{
      padding: $spacer-sm;
      background-color: currentColor;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      h4{
        margin: 0 0 $spacer-xxs 0;
      }
      table, th, td {
        text-align: left;
        font-size: min(max(12px, 4vw), 13px);
        font-weight: 400;
      }
      table {
        border-collapse: collapse;
      }
      th {
        font-weight: 500;
        padding-right: $spacer-sm;
      }
      .data{
        font-size: min(max(12px, 4vw), 13px);
        font-weight: 400;
        letter-spacing: -.01rem;
        line-height: 1.3;
        .strong{
          font-weight: 500;
          margin-right: $spacer-xxs;
        }
      }
    }
  }
  .button-wrapper{
    // margin-top: $spacer-md;
  }
  .video-wrapper{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .violation{
    position: relative;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23FF3849' fill-opacity='0.1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
      z-index: 20;
    }
  }
}





@media screen and (max-width: 768px) {
  section.blocks {
    .copy{
      p{
        column-count: 1;
      }
    }
  }
}
//
@media screen and (max-width: 576px) {
  section.blocks {
    .images-grid{
      grid-template-columns: 1fr;
    }
    .violation-grid{
      grid-template-columns: 1fr;
    }
    .colors-grid{
      grid-template-columns: 1fr;
    }
  }
}
