figure{
  position: relative;
  margin: 0;
  padding: 0;
  img{
    display: block;
    width: 100%;
    height: auto;
  }
}
figcaption{
  position: absolute;
  left: .75rem;
  bottom: $spacer-xs;
  font-size: min(max(9px, 4vw), 12px);
  font-weight: 400;
  letter-spacing: -.01rem;
  color: currentColor;
  margin: 0;
  padding: 0;
  z-index: 20;
}
